@import "./../../shared/styles/colors.variables.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 45%;
  height: 100%;

  @media (max-width: 1024px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 60%;
  }

  input {
    background: transparent;
    border-radius: 20px;
    border: 2px solid $blue;
    padding: 8px;
    margin: 5px;
    height: 5vh;
    color: $blue;

    @media (max-width: 1024px) {
      margin: 2px;
      height: 4vh;
    }
  }

  input:focus {
    border: 2px dashed $blue;
    color: $blue;
  }

  input::placeholder {
    color: rgba(9, 30, 90, 0.2);
  }

  .inputCont {
    display: flex;
    align-items: center;
    width: 100%;

    .item {
      width: 90%;
    }

    .item:invalid + .validity::after {
      content: "✖";
      padding: 10px;
      color: #8b0000;
      @media (max-width: 1024px) {
        padding: 5px;
      }
    }

    .item:valid + .validity::after {
      content: "✓";
      padding: 10px;
      color: #009000;
      @media (max-width: 1024px) {
        padding: 5px;
      }
    }
  }
  .validity {
    width: 10%;
  }

  .messageCont {
    display: flex;
    align-items: center;
    width: 100%;

    .message {
      border: 2px solid $blue;
      background: transparent;
      border-radius: 20px;
      padding: 8px;
      margin: 5px;
      width: 100%;
      height: 100px;
      resize: none;
      overflow: hidden;
      color: $blue;
      @media (max-width: 1024px) {
        margin: 2px;
        height: 70px;
      }
    }

    textarea:focus {
      border: dashed 2px $blue;
      color: $blue;
    }

    textarea::placeholder {
      color: rgba(9, 30, 90, 0.2);
    }

    .message:invalid + .validity::after {
      content: "✖";
      padding: 10px;
      color: #8b0000;
      @media (max-width: 1024px) {
        padding: 5px;
      }
    }

    .message:valid + .validity::after {
      content: "✓";
      padding: 10px;
      color: #009000;
      @media (max-width: 1024px) {
        padding: 5px;
      }
    }
  }

  .btnCont {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    color: #f5f5f5;
    padding-right: 10%;

    .count {
      color: $blue;
      font-size: 12px;
      width: 100%;
      text-align: right;
      @media (max-width: 1024px) {
        font-size: 8px;
      }
    }

    button {
      height: 5vh;
      @media (max-width: 1024px) {
        height: 4vh;
        font-size: 12px;
      }
    }
  }
}
