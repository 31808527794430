@import "./../../../shared//styles/colors.variables.scss";

.wrapper {
  width: 100%;
  height: 50vh;
  background: $paleblue;
  border-bottom: 2px dashed $blue;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60%;
      height: 90%;
      flex-wrap: wrap;
      @media (max-width: 1024px) {
        width: 90%;
        padding-bottom: 5%;
      }
    }
  }

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999999999;
    background-color: rgba(#000000, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      width: 60%;
      background-color: $paleblue;
      color: $blue;
      padding: 30px;
      @media (max-width: 768px) {
        width: 100%;
        height: 100%;
      }

      .title {
        font-size: 50px;
        width: 80%;
        text-align: left;
        font-weight: 600;

        @media (max-width: 1024px) {
          font-size: 30px;
        }

        @media (max-width: 768px) {
          font-size: 3vh;
          width: 100%;
        }
      }

      .content {
        font-size: 25px;
        width: 60%;
        text-align: left;
        @media (max-width: 1024px) {
          font-size: 2vh;
        }

        @media (max-width: 768px) {
          font-size: 2vh;
          width: 100%;
          padding: 5% 3% 5% 3%;
        }

        ul {
          list-style: circle;
        }
      }

      .secondTitle {
        font-size: 30px;
        font-weight: 600;
        width: 80%;
        text-align: left;
        @media (max-width: 1024px) {
          font-size: 2vh;
        }

        @media (max-width: 768px) {
          font-size: 3vh;
          width: 100%;
        }
      }

      .secondContent {
        @extend .content;

        ul {
          list-style: decimal;
        }
      }
    }
  }
}
