.left {
  position: absolute;
  left: 0;
  margin-left: 5%;
  @media (max-width: 1024px) {
    display: none;
  }
}

.right {
  position: absolute;
  right: 0;
  margin-right: 5%;
  @media (max-width: 1024px) {
    display: none;
  }
}
