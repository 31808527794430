@import "./../../../shared//styles/colors.variables.scss";

.wrapper {
  position: relative;
  z-index: 10;
  width: 100%;
  min-height: 50vh;
  background: $paleblue;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60%;
      height: 100%;
      font-size: 3vh;
      color: $blue;
      gap: 4vw;
      margin-top: 5%;

      @media (max-width: 1024px) {
        width: 90%;
        font-size: 2.5vh;
      }
      @media (max-width: 768px) {
        width: 90%;
        font-size: 2.2vh;
        flex-wrap: wrap;
      }
      @media (max-width: 425px) {
        font-size: 4vw;
      }

      .textCont {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58%;
        max-height: -webkit-fill-available;

        span {
          font-weight: 600;
        }

        @media (max-width: 1024px) {
          flex-direction: column;
        }

        @media (max-width: 768px) {
          width: 90%;
        }
      }

      .rightCont {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        row-gap: 2vh;
        width: 40%;
        height: 100%;

        @media (max-width: 768px) {
          width: 95%;
        }

        .infoCont {
          display: flex;
          align-items: center;
          text-align: center;
          font-size: 20px;
          background: #d3d3d3;
          position: relative;
          width: 100%;

          @media (max-width: 1024px) {
            background: transparent;
            flex-direction: column;
            gap: 2vh;
          }

          @media (max-width: 768px) {
            width: 100%;
            height: 45%;
            font-size: 14px;
            flex-direction: row;
          }

          .line {
            position: absolute;
            left: 50%;
            height: 100%;
            border-left: 0.5px solid #f5f5f5;

            @media (max-width: 1024px) {
              border-left: none;
            }
          }

          .builders {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            gap: 1vh;
          }
          .towers {
            @extend .builders;
          }

          img {
            @media (max-width: 1024px) {
              width: 50px;
            }
          }

          span {
            font-size: 32px;
            font-weight: 600;

            @media (max-width: 1024px) {
              font-size: 28px;
            }
          }
        }

        .pdf {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 1vh;
          font-size: 20px;
          font-weight: bold;

          @media (max-width: 1024px) {
            font-size: 16px;
          }

          img {
            width: 40px;

            @media (max-width: 768px) {
              width: 30px;
            }
          }
        }

        .infoMobile {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 10vw;
          width: 80%;
          min-height: 30%;
          padding: 0 10% 0 10%;
          font-weight: 600;
        }
      }
    }
  }
}
