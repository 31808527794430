@import "./../../shared/styles/colors.variables.scss";

.wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 30vh;

  .footerImage {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .leftCont {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    width: 40%;
    height: 100%;
    border-right: 2px dashed $paleblue;

    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 10px;
    }

    .logo {
      width: 100px;
      @media (max-width: 1024px) {
        width: 50px;
      }
    }

    span {
      text-align: center;
      color: #f5f5f5;
      font-size: 20px;

      @media (max-width: 1024px) {
        font-size: 2vw;
      }
    }
  }

  .rightCont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    gap: 5vh;
    width: 60%;
    height: 100%;

    @media (max-width: 1024px) {
      padding: 5%;
      gap: 0;
      justify-content: space-between;
      flex-direction: row;
    }

    .infoCont {
      display: flex;
      column-gap: 2vw;

      @media (max-width: 1024px) {
        align-items: flex-start;
        flex-direction: column;
        row-gap: 1vh;
      }

      .phoneCont {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f5f5f5;
        gap: 5px;
        @media (max-width: 1024px) {
          justify-content: end;
        }

        span {
          font-size: 22px;
          @media (max-width: 1024px) {
            font-size: 2vw;
          }
        }

        svg {
          width: 40px;
          @media (max-width: 1024px) {
            width: 30px;
          }
          @media (max-width: 425px) {
            width: 15px;
          }
        }
      }

      .phoneCont:hover {
        filter: drop-shadow(-2px 3px 8px #f5f5f5);
        svg {
          @media (max-width: 425px) {
            display: none;
          }
        }
      }

      .mailCont {
        @extend .phoneCont;
      }

      .addressCont {
        @extend .phoneCont;
      }
    }

    .linksCont {
      text-transform: uppercase;
      font-size: 25px;

      @media (max-width: 1024px) {
        font-size: 18px;
      }
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}
