@import "/src/shared/styles/colors.variables.scss";

.wrapper {
  .menu {
    display: none;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: black;
      justify-content: center;
      align-items: center;
      color: white;
    }
  }

  .footerCont {
    overflow: hidden;
    width: 100%;
    background-color: $paleblue;
  }

  .menuBtn {
    display: none;
    @media (max-width: 1024px) {
      display: flex; /* используем flex для центрирования содержимого */
      align-items: center; /* центрируем содержимое кнопки */
      position: fixed;
      top: 20px;
      right: 20px;
      width: 26px;
      height: 26px;
      cursor: pointer;
      z-index: 100000;
    }
  }

  .menuIcon {
    position: fixed;
    width: 50px;
    height: 50px;
    cursor: pointer;
    right: 10px;
    top: 10px;
    z-index: 9999999;

    .checkbox {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      cursor: pointer;
      z-index: 2;
      -webkit-touch-callout: none;
      position: absolute;
      opacity: 0;
    }
    div {
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 36px;
      height: 13px;
    }
    .menuSpan {
      position: absolute;
      display: block;
      width: 100%;
      height: 3px;
      background-color: $blue;
      border-radius: 1px;
      transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

      &:first-of-type {
        top: 0;
      }
      &:last-of-type {
        bottom: 0;
      }
    }
  }

  .menuIconActive {
    @extend .menuIcon;

    .checkbox {
      @extend .checkbox;
    }
    div {
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 36px;
      height: 13px;
    }
    .menuSpan {
      position: absolute;
      display: block;
      width: 100%;
      height: 3px;
      background-color: var(--bar-bg, rgb(255, 255, 255));
      border-radius: 1px;
      transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

      &:first-of-type {
        top: 0;
      }
      &:last-of-type {
        bottom: 0;
      }
    }
    .checkbox + div {
      span {
        &:first-of-type {
          transform: rotate(45deg);
          top: 5px;
        }
        &:last-of-type {
          transform: rotate(-45deg);
          bottom: 5px;
        }
      }
    }
  }
}

.menuSpanMain {
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  background-color: #fff;
  border-radius: 1px;
  transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

  &:first-of-type {
    top: 0;
  }
  &:last-of-type {
    bottom: 0;
  }
}
