@import "./../../../shared//styles/colors.variables.scss";

.wrapper {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 50vh;
  background: $paleblue;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 90%;

      @media (max-width: 768px) {
        width: 75%;
      }
      @media (max-width: 425px) {
        width: 80%;
      }
    }
  }
}
