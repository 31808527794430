@import "/src/shared/styles/colors.variables.scss";

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);

  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 30%;
    background-color: $paleblue;
    border: 2px dashed $blue;
    row-gap: 4vh;

    .titleCont {
      display: flex;
      flex-direction: column;
      row-gap: 1vh;
      text-align: center;
      h1 {
        font-size: 30px;
        color: $blue;
      }

      span {
        font-size: 20px;
        color: $blue;
      }
    }

    .btnCont {
      width: 100px;
      height: 40px;
    }
  }
}
