@import "/src/shared/styles/colors.variables.scss";

.wrapper {
  color: $paleblue;
  padding: 10px;
  font-weight: 600;
  font-size: 50px;
  -webkit-text-stroke: 0.1px $blue;
  @media (max-width: 768px) {
    font-size: 40px;
  }
  @media (max-width: 425px) {
    font-size: 30px;
  }
}
