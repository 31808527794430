@import "./../../../shared/styles/colors.variables.scss";

.wrapper {
  display: flex;
  width: 100%;
  height: 70vh;
  background: $paleblue;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 40%;
      height: 100%;

      @media (max-width: 1024px) {
        width: 90%;
        height: 50%;
      }

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $blue;
        font-size: 42px;
        height: 15%;

        @media (max-width: 1024px) {
          font-size: 28px;
        }
        @media (max-width: 768px) {
          font-size: 22px;
        }
      }

      .leftCont {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: dashed 2px $blue;
        border-right: dashed 2px $blue;
        position: relative;
        width: 100%;
        height: 100%;
        @media (max-width: 1024px) {
          border: none;
        }
      }
    }

    .rightCont {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 60%;
      color: $blue;
      gap: 8vw;
      padding-right: 10%;
      @media (max-width: 1024px) {
        width: 90%;
        gap: 15vw;
        padding-right: 0;
      }

      .man {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;

        img {
          width: 180px;
          @media (max-width: 1024px) {
            width: 90px;
          }
          @media (max-width: 768px) {
            width: 70px;
          }
        }

        .name {
          font-weight: 600;
          font-size: 2vw;

          @media (max-width: 768px) {
            font-size: 3vw;
          }
          @media (max-width: 425px) {
            font-size: 4vw;
          }
        }

        .position {
          font-size: 20px;
          @media (max-width: 1024px) {
            font-size: 1vw;
          }
          @media (max-width: 768px) {
            font-size: 2vw;
          }
          @media (max-width: 425px) {
            font-size: 3vw;
          }
        }

        a {
          font-weight: 600;
          font-size: 1.5vw;

          @media (max-width: 768px) {
            font-size: 2vw;
          }
          @media (max-width: 425px) {
            font-size: 3vw;
          }
        }

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1vh;
        }

        .phoneCont:hover {
          filter: drop-shadow(0px 4px 4px rgba(9, 30, 90, 0.5));
        }
        .mailCont:hover {
          filter: drop-shadow(0px 4px 4px rgba(9, 30, 90, 0.5));
        }

        .icon {
          width: 30px;
          height: 30px;

          @media (max-width: 1024px) {
            width: 2vw;
            height: 2vw;
          }

          @media (max-width: 768px) {
            width: 3vw;
            height: 3vw;
          }
          @media (max-width: 425px) {
            width: 5vw;
            height: 5vw;
          }
          path {
            fill: $blue;
          }
        }
      }
    }
  }
}
