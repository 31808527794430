@import "../../../../shared/styles/colors.variables.scss";

.header {
  display: flex;
  position: relative;
  justify-content: space-between;
  z-index: 100;
  width: 100%;
  height: 10vh;
  border-bottom: 1px solid #f5f5f5;
  padding: 0 50px 0 50px;

  @media (max-width: 1024px) {
    padding: 0 20px 0 20px;
    border: none;
  }

  .logoCont {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    img {
      max-width: 60px;
      max-height: 60px;
      @media (max-width: 1024px) {
        display: none;
      }
    }

    div {
      font-size: 30px;
      font-weight: 600;
      color: #f5f5f5;

      @media (max-width: 1024px) {
        font-size: 24px;
        // color: $blue;
      }
    }
  }

  .logoCont:hover {
    filter: drop-shadow(-2px 3px 8px #f5f5f5);
  }

  .linksCont {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 25px;
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .menuBtn {
    display: none;
    @media (max-width: 1024px) {
      display: flex; /* используем flex для центрирования содержимого */
      align-items: center; /* центрируем содержимое кнопки */
      position: fixed;
      top: 20px;
      right: 20px;
      width: 26px;
      height: 26px;
      cursor: pointer;
      z-index: 1;
    }
  }

  .menuBtn span,
  .menuBtn span::before,
  .menuBtn span::after {
    display: block;
    position: absolute;
    right: 0;
    width: 100%;
    height: 3px;
    background-color: #f5f5f5;
  }

  .menuBtn span::before {
    content: "";
    top: -8px;
  }

  .menuBtn span::after {
    content: "";
    top: 8px;
  }
}
