@import "./../../shared/styles/colors.variables.scss";

.wrapper {
  position: absolute;
  display: flex;
  right: 8%;
  bottom: 10%;
  z-index: 100000;

  @media (max-width: 1024px) {
    right: 15%;
  }
  @media (max-width: 768px) {
    right: 12%;
  }
  @media (max-width: 425px) {
    right: 20%;
  }
  .arrow {
    position: fixed;
    cursor: pointer;
    @media (max-width: 1024px) {
      width: 70px;
      height: 70px;
    }
  }
}

.wrapper2 {
  @extend .wrapper;

  bottom: 40%;

  @media (max-width: 1024px) {
    bottom: 37%;
  }
  @media (max-width: 768px) {
    bottom: 37%;
  }
  @media (max-width: 425px) {
    bottom: 37%;
  }
}
