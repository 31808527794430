@import "/src/shared/styles/colors.variables.scss";

.sendButton {
  width: 100%;
  height: 100%;
  border: 0;
  font-size: 20px;
  border-radius: 20px;
  background: rgba(9, 30, 90, 0.8);
  color: #f5f5f5;
  cursor: pointer;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    background: $blue;
    font-size: 13px;
  }
}

button:hover {
  background: rgba(9, 30, 90, 0.9);

  span {
    filter: drop-shadow(-2px 3px 8px #f5f5f5a2);
  }
}
button:active {
  background-color: $blue;
  filter: drop-shadow(-2px 3px 8px #f5f5f5);

  span {
    filter: drop-shadow(-2px 3px 8px #f5f5f5);
  }

  transform: translateY(4px);
}
