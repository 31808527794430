@import "/src/shared/styles/colors.variables.scss";

.swiper {
  width: 100%;
  height: 100%;
}

.swiperSlide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper div {
  color: $blue;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;
  cursor: pointer;
  background-color: #d3d3d3;
  border-right: 1px solid #f5f5f5;

  @media (max-width: 768px) {
    padding: 5px;
    width: 90%;
    height: 33%;
    border-right: none;
  }

  span {
    width: 80%;
    font-size: 3vh;
    text-align: center;
    color: $blue;
    margin-top: 30px;
    letter-spacing: 1px;
    font-weight: 600;

    @media (max-width: 1024px) {
      width: 90%;
      font-size: 2.5vh;
      margin-top: 5px;
      letter-spacing: 0;
    }
    @media (max-width: 768px) {
      width: 90%;
      font-size: 2vh;
      flex-wrap: wrap;
    }
  }
}
.item:hover {
  border: 2px dashed $blue;
}

.firstItem {
  @extend .item;
  background: #e6e6e6;
}
.secondItem {
  @extend .item;
  background: #e0e0e0;
}
.thirdItem {
  @extend .item;
  background: #d3d3d3;
}
