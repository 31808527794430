@font-face {
  font-family: Comfortaa Bold;
  src: local("comfortaa-bold"),
    url("../../assets/fonts/Comfortaa-Bold.ttf") format("opentype");
}
@font-face {
  font-family: Comfortaa Light;
  src: local("comfortaa-light"),
    url("../../assets/fonts/Comfortaa-Light.ttf") format("opentype");
}
@font-face {
  font-family: Comfortaa Medium;
  src: local("comfortaa-medium"),
    url("../../assets/fonts/Comfortaa-Medium.ttf") format("opentype");
}
@font-face {
  font-family: Comfortaa Regular;
  src: local("comfortaa-regular"),
    url("../../assets/fonts/Comfortaa-Regular.ttf") format("opentype");
}
@font-face {
  font-family: Comfortaa Semi Bold;
  src: local("comfortaa-semibold"),
    url("../../assets/fonts/Comfortaa-SemiBold.ttf") format("opentype");
}
@font-face {
  font-family: Evolventa Regular;
  src: local("evolventa-regular"),
    url("../../assets/fonts/Evolventa-Regular.ttf") format("opentype");
}
@font-face {
  font-family: Evolventa Bold;
  src: local("evolventa-bold"),
    url("../../assets/fonts/Evolventa-Bold.ttf") format("opentype");
}

@import "./colors.variables.scss";

* {
  font-family: Evolventa Regular;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  scrollbar-width: thin;
  scrollbar-color: $blue rgb(230, 230, 230);

  ::selection {
    color: $paleblue;
    background: $blue;
  }
}

a {
  text-decoration: none;
  color: $blue;
}

body::-webkit-scrollbar {
  width: 12px; /* ширина всей полосы прокрутки */
}

body::-webkit-scrollbar-track {
  background: rgb(230, 230, 230); /* цвет зоны отслеживания */
}

body::-webkit-scrollbar-thumb {
  background-color: $blue; /* цвет бегунка */
  border-radius: 20px; /* округлось бегунка */
  border: 3px solid rgb(230, 230, 230); /* отступ вокруг бегунка */
}

.mobile {
  display: none !important;
}

@media (max-width: 768px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }
}
