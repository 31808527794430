@import "../../../shared/styles/colors.variables.scss";

.wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100vh;
  @media (max-width: 1024px) {
    border-bottom: 2px dashed $blue;
  }

  .mainImageCont {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/images/MainLogo.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: $paleblue;
  }

  .mainMobile {
    position: absolute;
    max-width: 100%;
    min-height: 100%;

    img {
      max-width: 100%;
      min-height: 100%;
    }

    @media (max-width: 1024px) {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .main {
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    @media (max-width: 1024px) {
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10;
    align-items: center;
    width: 100%;
    height: 90vh;
    gap: 10vh;

    .title {
      font-size: 60px;
      color: #f5f5f5;
      text-align: center;
      margin: 0 10% 0 10%;

      @media (max-width: 1024px) {
        margin: 0;
        font-size: 32px;
        animation-name: none;
      }

      @media (max-width: 425px) {
        margin: 0 10% 0 10%;
        font-size: 24px;
      }
    }

    .btnCont {
      width: 15vw;
      height: 6vh;
      @media (max-width: 1024px) {
        width: 25vw;
      }
      @media (max-width: 768px) {
        width: 50vw;
      }
    }

    .infoCont {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 10px;
      grid-template-areas: "phone mail address";

      @media (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
          "phone mail"
          "address address";
        animation-name: none;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
          "phone"
          "mail"
          "address";
        animation-name: none;
      }

      -webkit-animation-delay: 0s;
      -moz-animation-delay: 0s;
      -ms-animation-delay: 0s;
      animation-delay: 0s;

      .phoneCont {
        grid-area: phone;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f5f5f5;
        gap: 1vh;

        @media (max-width: 1024px) {
          // color: $blue;
          padding: 5px;
        }

        img {
          @media (max-width: 1024px) {
            width: 40px;
          }
        }

        svg {
          @media (max-width: 1024px) {
            width: 40px;
            path {
              // fill: $blue;
            }
          }
        }

        span {
          font-size: 22px;
          @media (max-width: 1024px) {
            font-size: 18px;
          }
        }
      }

      .phoneCont:hover {
        filter: drop-shadow(-2px 3px 8px #f5f5f5);
      }

      .mailCont {
        grid-area: mail;
        @extend .phoneCont;
      }

      .addressCont {
        grid-area: address;
        @extend .phoneCont;
      }
    }

    .iconsCont {
      display: flex;
      margin-top: 200px;
      width: 60%;
      justify-content: space-between;

      animation-duration: 3s;
      animation-name: iconsin;

      @keyframes iconsin {
        from {
          margin-right: 100%;
        }

        to {
          margin-right: 0%;
        }
      }

      .iconItem {
        display: flex;
        align-items: center;
        color: #f5f5f5;
        font-size: 20px;

        img {
          margin: 10px;
        }
      }
    }
  }
}
