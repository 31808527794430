@import "/src/shared/styles/colors.variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 100%;
  height: 50vh;
  background: $paleblue;
  border-bottom: 2px dashed $blue;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    height: 80%;

    @media (max-width: 1024px) {
      width: 90%;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 1vw;
      width: 100%;
      height: 100%;

      @media (max-width: 1024px) {
        width: 90%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }

      .b2b {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 40%;
        height: 100%;
        background-color: #d2d2d2;
        cursor: pointer;
        font-size: 90px;
        color: $blue;
        font-weight: 600;
        text-shadow: 10px 10px $paleblue;
        @media (max-width: 1024px) {
          font-size: 50px;
          text-shadow: 8px 8px $paleblue;
        }
        @media (max-width: 768px) {
          font-size: 40px;
          text-shadow: 6px 6px $paleblue;
        }

        img {
          width: 150px;
          @media (max-width: 1024px) {
            width: 100px;
          }
          @media (max-width: 768px) {
            width: 70px;
          }
        }
      }
      .b2b:hover {
        border: 2px dashed $blue;
      }

      .b2g {
        @extend .b2b;
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);

  .item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 45%;
    height: 50%;
    background-color: $paleblue;
    padding: 35px;
    z-index: 99999999999;

    @media (max-width: 1024px) {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
  ul {
    color: $blue;
    font-size: 30px;
    font-weight: bold;

    @media (max-width: 1024px) {
      font-size: 28px;
    }

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
}
